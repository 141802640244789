<template>
  <el-dialog
    append-to-body
    title="修改稽核项目"
    :visible.sync="visible"
    width="680px"
    :show-close="true"
  >
    <el-form
      ref="optionForm"
      :model="auditOption"
      :rules="auditOptionRules"
      class="option-form"
      label-width="150px"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item prop="auditSumAmount" label="稽核结算价税合计：">
            <el-switch v-model="auditOption.auditSumAmount"> </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12"
          ><el-form-item prop="auditSumAmountValue" label="价税合计上下浮动：">
            <el-input-number
              v-model="auditOption.auditSumAmountValue"
              :precision="2"
              :step="5"
              :min="0"
              :max="1000"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item prop="auditTaxAmount" label="稽核订单总计税额：">
            <el-switch v-model="auditOption.auditTaxAmount"> </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="auditTaxAmountValue" label="总计税额上下浮动：">
            <el-input-number
              v-model="auditOption.auditTaxAmountValue"
              :precision="2"
              :step="1"
              :min="0"
              :max="1000"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item prop="auditInvoiceVi" label="稽核查验发票状态：">
            <el-switch v-model="auditOption.auditInvoiceVi"> </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="auditInvoiceCs" label="稽核发票签章状态：">
            <el-switch v-model="auditOption.auditInvoiceCs"> </el-switch>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item prop="auditInvoiceIm" label="稽核进项查询状态：">
            <el-switch v-model="auditOption.auditInvoiceIm"> </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="auditInvoiceTi" label="稽核票据影像状态：">
            <el-switch v-model="auditOption.auditInvoiceTi"> </el-switch>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item prop="auditBuyerName" label="稽核发票购方名称：">
            <el-switch v-model="auditOption.auditBuyerName"> </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="auditBuyerTaxNo" label="稽核发票购方税号：">
            <el-switch v-model="auditOption.auditBuyerTaxNo"> </el-switch>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item prop="auditBuyerAddressTel" label="稽核购方地址电话：">
            <el-switch v-model="auditOption.auditBuyerAddressTel"> </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="auditBuyerBankAccount" label="稽核购方银行账号：">
            <el-switch v-model="auditOption.auditBuyerBankAccount"> </el-switch>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item prop="auditSellerName" label="稽核发票销方名称：">
            <el-switch v-model="auditOption.auditSellerName"> </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="auditSellerTaxNo" label="稽核发票销方税号：">
            <el-switch v-model="auditOption.auditSellerTaxNo"> </el-switch>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item prop="auditSellerAddressTel" label="稽核销方地址电话：">
            <el-switch v-model="auditOption.auditSellerAddressTel"> </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            prop="auditSellerBankAccount"
            label="稽核销方银行账号："
          >
            <el-switch v-model="auditOption.auditSellerBankAccount">
            </el-switch>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item prop="auditInvoiceCr" label="稽核确认收票状态：">
            <el-switch v-model="auditOption.auditInvoiceCr"> </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12"></el-col>
      </el-row>
    </el-form>
    <div slot="footer">
      <el-button type="default" :loading="loading" @click="handleCancelUpdate"
        >取消</el-button
      >
      <el-button type="primary" :loading="loading" @click="handleConfirmUpdate"
        >确认</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { reqUpdateAuditOption } from "@/service/coo/trade.js";

export default {
  name: "SettlementEditOption",
  data() {
    return {
      visible: false,
      auditOption: {},

      auditOptionRules: {},
      loading: false,
    };
  },
  created() {},
  methods: {
    // 修改项目弹窗
    showDialog(trade) {
      if (!trade || !trade.id || trade.id < 0) {
        this.toast("修改失败，没有指定订单信息！", "warning");
        return;
      }
      if (trade.sheetStage == "CS") {
        this.toast("修改失败，不能修改已作废的订单！", "warning");
        return;
      }
      if (trade.sheetStage == "WJ") {
        this.toast("修改失败，不能修改已完结的订单！", "warning");
        return;
      }
      this.auditOption = trade;
      this.visible = true;
    },

    // 处理取消修改
    handleCancelUpdate() {
      this.visible = false;
    },

    // 处理确认修改
    async handleConfirmUpdate() {
      const { success } = await reqUpdateAuditOption(this.auditOption);
      if (success) {
        this.toast("修改成功，订单的稽核项目已更新。", "success");
        this.$emit("success");
        this.visible = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.option-form {
  padding: 0px 30px 20px 30px;
  text-align: left;

  .el-form-item {
    margin-bottom: 22px;
  }

  ::v-deep .el-input {
    width: 100% !important;
  }

  .el-input-number {
    line-height: 30px;
    width: 150px;
  }
}
</style>
