<template>
  <div class="dl-audit-option">
    <div class="dl-audit-option-item">
      <label class="option-t">稽核结算价税合计：</label>
      <span class="option-y" v-if="settlement.auditSumAmount">是</span
      ><span class="option-n" v-else>否</span>
    </div>
    <div class="dl-audit-option-item">
      <label class="option-t">价税合计上下浮动：</label
      ><span class="option-v"> {{ settlement.auditSumAmountValue }}</span>
    </div>
    <div class="dl-audit-option-item">
      <label class="option-t">稽核订单总计税额：</label>
      <span class="option-y" v-if="settlement.auditTaxAmount">是</span
      ><span class="option-n" v-else>否</span>
    </div>
    <div class="dl-audit-option-item">
      <label class="option-t">总计税额上下浮动：</label
      ><span class="option-v"> {{ settlement.auditTaxAmountValue }}</span>
    </div>
    <div class="dl-audit-option-item">
      <label class="option-t">稽核查验发票状态：</label>
      <span class="option-y" v-if="settlement.auditInvoiceVi">是</span
      ><span class="option-n" v-else>否</span>
    </div>
    <div class="dl-audit-option-item">
      <label class="option-t">稽核发票签章状态：</label>
      <span class="option-y" v-if="settlement.auditInvoiceCs">是</span
      ><span class="option-n" v-else>否</span>
    </div>
    <div class="dl-audit-option-item">
      <label class="option-t">稽核进项查询状态：</label>
      <span class="option-y" v-if="settlement.auditInvoiceIm">是</span
      ><span class="option-n" v-else>否</span>
    </div>
    <div class="dl-audit-option-item">
      <label class="option-t">稽核票据影像状态：</label>
      <span class="option-y" v-if="settlement.auditInvoiceTi">是</span
      ><span class="option-n" v-else>否</span>
    </div>
    <div class="dl-audit-option-item">
      <label class="option-t">稽核确认收票状态：</label>
      <span class="option-y" v-if="settlement.auditInvoiceCr">是</span
      ><span class="option-n" v-else>否</span>
    </div>
    <div class="dl-audit-option-item">
      <label class="option-t">稽核发票购方名称：</label>
      <span class="option-y" v-if="settlement.auditBuyerName">是</span
      ><span class="option-n" v-else>否</span>
    </div>
    <div class="dl-audit-option-item">
      <label class="option-t">稽核发票购方税号：</label>
      <span class="option-y" v-if="settlement.auditBuyerTaxNo">是</span
      ><span class="option-n" v-else>否</span>
    </div>
    <div class="dl-audit-option-item">
      <label class="option-t">稽核购方地址电话：</label>
      <span class="option-y" v-if="settlement.auditBuyerAddressTel">是</span
      ><span class="option-n" v-else>否</span>
    </div>
    <div class="dl-audit-option-item">
      <label class="option-t">稽核购方银行账号：</label>
      <span class="option-y" v-if="settlement.auditBuyerBankAccount">是</span
      ><span class="option-n" v-else>否</span>
    </div>
    <div class="dl-audit-option-item">
      <label class="option-t">稽核发票销方名称：</label>
      <span class="option-y" v-if="settlement.auditSellerName">是</span
      ><span class="option-n" v-else>否</span>
    </div>
    <div class="dl-audit-option-item">
      <label class="option-t">稽核发票销方税号：</label>
      <span class="option-y" v-if="settlement.auditSellerTaxNo">是</span
      ><span class="option-n" v-else>否</span>
    </div>
    <div class="dl-audit-option-item">
      <label class="option-t">稽核销方地址电话：</label>
      <span class="option-y" v-if="settlement.auditSellerAddressTel">是</span
      ><span class="option-n" v-else>否</span>
    </div>
    <div class="dl-audit-option-item">
      <label class="option-t">稽核销方银行账号：</label>
      <span class="option-y" v-if="settlement.auditSellerBankAccount">是</span
      ><span class="option-n" v-else>否</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SettlementAuditOption",
  props: ["settlement"],
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.dl-audit-option {
  padding: 0px 20px;

  .dl-audit-option-item {
    width: 280px;
    margin-top: 10px;
    display: inline-flex;

    .option-t {
      width: 140px;
      color: #666666;
    }

    .option-y {
      color: #409eff;
    }

    .option-n {
      color: #3d3d3d;
    }

    .option-v {
      color: #f56c6c;
      font-weight: 800;
      display: inline-block;
    }
  }
}
</style>
