<template>
  <span :class="'value-match-' + (value ? 'y' : 'n')">
    {{ value ? "已通过" : "未通过" }}
  </span>
</template>

<script>
export default {
  name: "SettlementAuditValueMatch",
  props: ["value"],
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.value-match-y {
  color: #67c23a;
}
.value-match-n {
  color: #e6a23c;
}
</style>
