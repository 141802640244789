<template>
  <div class="dl-trade-detail">
    <!-- 主要信息 -->
    <div class="detail-head">
      <div class="head-item">
        <label>结算单号：</label>
        <span>{{ settlement.sheetCode || "--" }}</span>
      </div>
      <div class="head-item">
        <label>结算金额：</label>
        <span>{{ fmtAmount(settlement.sumAmount) }}</span>
      </div>
      <div class="head-item">
        <label>结算日期：</label>
        <span>{{ settlement.tradeTime }}</span>
      </div>
      <div class="head-item">
        <label>结算阶段：</label>
        <span>{{
          getDictLabel(dictionary.tradeSheetStage, settlement.sheetStage)
        }}</span>
      </div>
      <div class="head-item" v-if="['JH'].indexOf(this.scene) != -1">
        <label>稽核状态：</label>
        <span>{{
          getDictLabel(dictionary.tradeAuditStatus, settlement.auditStatus)
        }}</span>
      </div>
      <div class="head-item" v-if="['BI'].indexOf(this.scene) != -1">
        <label>订单状态：</label>
        <span>{{
          getDictLabel(dictionary.tradeSheetStatus, settlement.sheetStatus)
        }}</span>
      </div>
      <div class="head-item" v-if="['BI'].indexOf(this.scene) != -1">
        <label>开票状态：</label>
        <span>{{
          getDictLabel(dictionary.tradeBillStatus, settlement.billStatus)
        }}</span>
      </div>
      <div class="head-item" v-if="['RI'].indexOf(this.scene) != -1">
        <label>交票状态：</label>
        <span>{{
          getDictLabel(dictionary.tradeReceiveStatus, settlement.receiveStatus)
        }}</span>
      </div>
    </div>
    <!-- 次要信息 -->
    <el-collapse v-model="activeNames" class="dl-collapse">
      <el-collapse-item title="结算信息" name="C">
        <div class="detail-cont">
          <div class="cont-item">
            <label>需方主体名称：</label>
            <span>{{ settlement.buyerMainName || "--" }}</span>
          </div>
          <div class="cont-item">
            <label>需方主体编码：</label>
            <span>{{ settlement.buyerCode || "--" }}</span>
          </div>
          <div class="cont-item">
            <label>供方主体名称：</label>
            <span>{{ settlement.sellerMainName || "--" }}</span>
          </div>
          <div class="cont-item">
            <label>供方主体编码：</label>
            <span>{{ settlement.sellerMainCode || "--" }}</span>
          </div>
          <div class="cont-item">
            <label>购方企业名称：</label>
            <span>{{ settlement.buyerName || "--" }}</span>
          </div>
          <div class="cont-item">
            <label>购方企业税号：</label>
            <span>{{ settlement.buyerTaxNo || "--" }}</span>
          </div>
          <div class="cont-item">
            <label>购方地址电话：</label>
            <span>{{ settlement.buyerAddressTel || "--" }}</span>
          </div>
          <div class="cont-item">
            <label>购方银行账号：</label>
            <span>{{ settlement.buyerBankAccount || "--" }}</span>
          </div>
          <div class="cont-item">
            <label>销方企业名称：</label>
            <span>{{ settlement.sellerName || "--" }}</span>
          </div>
          <div class="cont-item">
            <label>销方企业税号：</label>
            <span>{{ settlement.sellerTaxNo || "--" }}</span>
          </div>
          <div class="cont-item">
            <label>销方地址电话：</label>
            <span>{{ settlement.sellerAddressTel || "--" }}</span>
          </div>
          <div class="cont-item">
            <label>销方银行账号：</label>
            <span>{{ settlement.sellerBankAccount || "--" }}</span>
          </div>
          <div class="cont-item">
            <label>订单未税金额：</label>
            <span>{{ fmtAmount(settlement.amount) }}</span>
          </div>
          <div class="cont-item">
            <label>订单总计税额：</label>
            <span>{{ fmtAmount(settlement.taxAmount) }}</span>
          </div>
          <div class="cont-item">
            <label>结算价税合计：</label>
            <span>{{ fmtAmount(settlement.sumAmount) }}</span>
          </div>
          <div class="cont-item">
            <label>数据合规状态：</label>
            <compliance-status
              :value="settlement.complianceStatus"
              :label="
                getDictLabel(
                  dictionary.tradeComplianceStatus,
                  settlement.complianceStatus
                )
              "
            ></compliance-status>
          </div>
          <div class="cont-item">
            <label>明细是否完整：</label>
            <dl-yes-or-no :value="settlement.itemCompleted"></dl-yes-or-no>
          </div>
          <div class="cont-item">
            <label>明细的总数量：</label>
            <span>{{ settlement.itemTotalCount }}</span>
          </div>
          <div class="cont-item">
            <label>明细合规数量：</label>
            <span
              :class="
                settlement.itemCompletedCount != settlement.itemTotalCount
                  ? 'yc'
                  : 'zc'
              "
              >{{ settlement.itemCompletedCount }}</span
            >
          </div>
          <div class="cont-item">
            <label>购方是否完整：</label>
            <dl-yes-or-no :value="settlement.buyerCompleted"></dl-yes-or-no>
          </div>
          <div class="cont-item">
            <label>购方异常提示：</label>
            <span :class="settlement.buyerException ? 'yc' : 'zc'">{{
              settlement.buyerException || "--"
            }}</span>
          </div>
          <div class="cont-item">
            <label>销方是否完整：</label>
            <dl-yes-or-no :value="settlement.sellerCompleted"></dl-yes-or-no>
          </div>
          <div class="cont-item">
            <label>销方异常提示：</label>
            <span :class="settlement.sellerException ? 'yc' : 'zc'">{{
              settlement.sellerException || "--"
            }}</span>
          </div>
          <div class="cont-item">
            <label>金额是否完整：</label>
            <dl-yes-or-no :value="settlement.amountCompleted"></dl-yes-or-no>
          </div>
          <div class="cont-item">
            <label>金额异常提示：</label>
            <span :class="settlement.amountException ? 'yc' : 'zc'">{{
              settlement.amountException || "--"
            }}</span>
          </div>
          <div class="cont-item">
            <label>订单开票状态：</label>
            <span>{{
              getDictLabel(dictionary.tradeBillStatus, settlement.billStatus)
            }}</span>
          </div>
          <div class="cont-item">
            <label>订单交票状态：</label>
            <span>{{
              getDictLabel(
                dictionary.tradeReceiveStatus,
                settlement.receiveStatus
              )
            }}</span>
          </div>
          <div class="cont-item">
            <label>结算稽核状态：</label>
            <span>{{
              getDictLabel(dictionary.tradeAuditStatus, settlement.auditStatus)
            }}</span>
          </div>
          <div class="cont-item">
            <label>订单回写状态：</label>
            <span>{{
              getDictLabel(
                dictionary.tradeWriteBackStatus,
                settlement.writeBackStatus
              )
            }}</span>
          </div>
          <div class="cont-item">
            <label>订单回写地址：</label>
            <span>{{ settlement.writeBackUrl || "--" }}</span>
          </div>
          <div class="cont-item">
            <label>回写响应信息：</label>
            <span>{{ settlement.writeBackMessage || "--" }}</span>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { fmtMoney } from "@/assets/js/format-util";
import DlYesOrNo from "@/components/widgets/DlYesOrNo.vue";
import ComplianceStatus from "@/views/cooperate/settlement/widgets/ComplianceStatus.vue";

export default {
  components: { DlYesOrNo, ComplianceStatus },
  name: "SettlementTradeBasic",
  props: {
    // 应用场景
    scene: {
      type: String,
      default: "XQ", //RI=收票,BI=开票,JH=稽核,XQ=详情
    },
    // 是否折叠
    isCollapse: {
      type: Boolean,
      default: true,
    },
    // 协同角色
    cooRole: {
      type: String,
      default: "SS",
    },
    // 订单对象
    settlement: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      // 活动标签
      activeNames: [],
      dictionary: {
        tradeSheetStage: [],
        tradeSheetStatus: [],
        tradeAuditStatus: [],
        tradeBillStatus: [],
        tradeReceiveStatus: [],
        tradeWriteBackStatus: [],
        tradeComplianceStatus: [],
      },
    };
  },
  async created() {
    this.activeNames = this.isCollapse ? [] : ["C"];
    await this.initDict();
  },
  methods: {
    // 初始字典
    async initDict() {
      const { data } = await this.batchGetDicts({
        types: [
          "trade_sheet_stage",
          "trade_sheet_status",
          "trade_audit_status",
          "trade_bill_status",
          "trade_receive_status",
          "trade_write_back_status",
          "data_compliance_status",
          "trade_upload_status",
        ],
      });
      this.dictionary.tradeSheetStage = data["trade_sheet_stage"];
      this.dictionary.tradeSheetStatus = data["trade_sheet_status"];
      this.dictionary.tradeAuditStatus = data["trade_audit_status"];
      this.dictionary.tradeBillStatus = data["trade_bill_status"];
      this.dictionary.tradeReceiveStatus = data["trade_receive_status"];
      this.dictionary.tradeWriteBackStatus = data["trade_write_back_status"];
      this.dictionary.tradeComplianceStatus = data["data_compliance_status"];
    },
    // 格式金额
    fmtAmount(val) {
      return fmtMoney(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.dl-trade-detail {
  padding: 0px;

  .detail-head {
    background-color: #ebf4ff;
    padding: 20px 20px 8px 20px;

    .head-item {
      width: 360px;
      font-size: 16px;
      margin-bottom: 12px;
      display: inline-block;

      label {
        width: 80px;
        color: #666666;
        display: inline-flex;
      }

      span {
        color: #333333;
        display: inline-flex;
      }
    }
  }

  .detail-cont {
    padding: 20px 20px 8px 20px;

    .cont-item {
      width: 360px;
      font-size: 14px;
      padding: 0px 10px 10px 0px;
      display: inline-flex;

      label {
        width: 100px;
        color: #888888;
        flex: none;
      }

      span {
        color: #555555;
        display: inline-flex;
      }

      .yc {
        color: #f5222d;
      }

      .zc {
        color: #555555;
      }
    }
  }

  .dl-collapse {
    ::v-deep .el-collapse-item__header {
      padding: 0px 20px;
    }
  }
}
</style>
